export const info = {
  name: 'info',
  path: '/info',
  component: () => import('@/views_mobile/member/info'),
  meta: {
    layout: 'mobile-default',
    text: '회원정보수정',
    gnb: false
  }
}
