export const result = {
  name: 'resultMain',
  path: '/resultMain',
  component: () => import('@/views_mobile/member/result'),
  redirect: {
    name: 'result'
  },
  meta: {
    layout: 'mobile-default',
    text: '쿠폰',
    gnb: false
  },
  children: [
    {
      name: 'result',
      path: '/result',
      component: () => import('@/views_mobile/member/result/result'),
      meta: {
        layout: 'mobile-default',
        text: '쿠폰',
        gnb: false
      }
    }
  ]
}
