export const bet = {
  name: 'bet',
  path: '/bet',
  component: () => import('@/views_mobile/member/bet'),
  redirect: {
    name: 'betList'
  },
  meta: {
    layout: 'mobile-default',
    text: '베팅리스트',
    gnb: false,
    gnbMenu: 'betList'
  },
  children: [
    {
      name: 'betList',
      path: '/betList',
      component: () => import('@/views_mobile/member/bet/betList'),
      meta: {
        layout: 'mobile-default',
        text: '베팅리스트',
        gnb: false,
        gnbMenu: 'betList'
      }
    }
  ]
}
