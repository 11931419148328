import { createApp } from 'vue'
import router from './router/index'
import routerMobile from './router_mobile/index'
import store from './store'
import App from './App.vue'
import vfmPlugin from 'vue-final-modal'
import { isMobile } from '@/libs/utils'
import CommonMixin from '@/plugins/common'

import { createI18n } from 'vue-i18n'
import i18nMessage from '@/data/i18n'
import mitt from 'mitt'
import { getLangCookie, setLangCookie } from '@/libs/auth-helper'

let lang = getLangCookie()

if (!lang) {
  lang = 'ko'
  setLangCookie(lang)
}
document.body.classList = lang

const i18n = createI18n({
  // something vue-i18n options here ...
  legacy: false, // you must set `false`, to use Composition API
  locale: lang, // set locale
  fallbackLocale: 'en', // set fallback locale
  globalInjection: true,
  messages: i18nMessage.messages // set locale messages
})

const app = createApp(App)

const emitter = mitt()

app.config.globalProperties.emitter = emitter

app.use(store)
  .use(isMobile() ? routerMobile : router)
  .use(vfmPlugin).use(i18n).mixin(CommonMixin)
  .mount('body')
