import httpClient from '@/libs/http-client'

export function cashIn (params) {
  const url = '/api/cash/in'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function cashOut (params) {
  const url = '/api/cash/out'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function cashList (params) {
  const url = '/api/cash/list/'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function cashOutList (params) {
  const url = '/api/cash/list/out'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function cashDelete (params) {
  const url = '/api/cash/del'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function cashToGameMoney (params) {
  const url = '/api/cash/inGameMoney'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function gameMoneyToCash (params) {
  const url = '/api/cash/outGameMoney'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getGameMoney (params) {
  const url = '/api/cash/getUserCash'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getAccount (params) {
  const url = '/api/cash/getAcc'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
