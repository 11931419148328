export const point = {
  name: 'pointMain',
  path: '/pointMain',
  component: () => import('@/views_mobile/member/point'),
  redirect: {
    name: 'point'
  },
  meta: {
    layout: 'mobile-default',
    text: '포인트전환',
    gnb: false
  },
  children: [
    {
      name: 'point',
      path: '/point',
      component: () => import('@/views_mobile/member/point/point'),
      meta: {
        layout: 'mobile-default',
        text: '베팅리스트',
        gnb: false
      }
    }
  ]
}
