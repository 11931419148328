export const attendance = {
  name: 'attendance',
  path: '/attendance',
  component: () => import('@/views_mobile/member/attendance'),
  meta: {
    layout: 'mobile-default',
    text: '출석부',
    gnb: false
  }
}
