export const msg = {
  name: 'msgMain',
  path: '/msgMain',
  component: () => import('@/views_mobile/member/msg'),
  redirect: {
    name: 'msg'
  },
  meta: {
    layout: 'mobile-default',
    text: '쪽지',
    gnb: false
  },
  children: [
    {
      name: 'msg',
      path: '/msg',
      component: () => import('@/views_mobile/member/msg/msg'),
      meta: {
        layout: 'mobile-default',
        text: '쪽지',
        gnb: false
      }
    },
    {
      name: 'msgRead',
      path: '/msgRead/:msgIdx',
      props: true,
      component: () => import('@/views_mobile/member/msg/read'),
      meta: {
        layout: 'mobile-default',
        text: '쪽지',
        gnb: false
      }
    }
  ]
}
