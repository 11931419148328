export const attendendance = {
  name: 'attendendance',
  path: '/attendendance',
  component: () => import('@/views/member/attendendance'),
  meta: {
    layout: 'default',
    text: '출석부',
    gnb: false
  }
}
