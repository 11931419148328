<template>
  <input :type="type" :class="className" :placeholder="placeholder" @keyup="onKeyup" v-model="model">
</template>

<script>
export default {
  name: 'UiInput',
  props: [
    'type',
    'className',
    'placeholder',
    'modelValue'
  ],
  emits: [
    'input'
  ],
  data () {
    return {
      model: ''
    }
  },
  watch: {
    modelValue (value) {
      this.model = value
    }
  },
  created () {
    if (this.modelValue) {
      this.model = this.modelValue
    }
  },
  methods: {
    onKeyup (event) {
      this.$emit('input', this.model, event)
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
