export const rotary = {
  name: 'rotary',
  path: '/rotary',
  meta: {
    layout: 'default',
    text: '로터리',
    gnb: true,
    sub: false
  }
}
