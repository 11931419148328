export const myinfo = {
  name: 'myinfo',
  path: '/myinfo',
  component: () => import('@/views_mobile/member/mypage/myinfo'),
  meta: {
    layout: 'mobile-empty',
    text: '베팅리스트',
    gnb: false,
    gnbMenu: 'myinfo'
  }
}
