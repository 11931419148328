export const winlose = {
  name: 'winlose',
  path: '/winlose',
  component: () => import('@/views_mobile/member/winlose'),
  meta: {
    layout: 'mobile-default',
    text: '윈루즈정산',
    gnb: false
  }
}
