export const partner = {
  name: 'partner',
  path: '/partner',
  component: () => import('@/views/member/partner/index.vue'),
  redirect: {
    name: 'partnerCashList'
  },
  meta: {
    layout: 'default',
    text: '파트너 페이지',
    gnb: false
  },
  children: [
    {
      name: 'partnerCashList',
      path: '/partner/cashList',
      component: () => import('@/views/member/partner/cashList.vue'),
      meta: {
        layout: 'default',
        text: '파트너 페이지',
        gnb: false
      }
    },
    {
      name: 'partnerBettingList',
      path: '/partner/bettingList',
      component: () => import('@/views/member/partner/bettingList.vue'),
      meta: {
        layout: 'default',
        text: '파트너 페이지',
        gnb: false
      }
    },
    {
      name: 'partnerList',
      path: '/partner/partnerList',
      component: () => import('@/views/member/partner/partnerList.vue'),
      meta: {
        layout: 'default',
        text: '파트너 페이지',
        gnb: false
      }
    },
    {
      name: 'partnerCalculationList',
      path: '/partner/partnerCalculationList',
      component: () => import('@/views/member/partner/calculationList.vue'),
      meta: {
        layout: 'default',
        text: '파트너 페이지',
        gnb: false
      }
    },
    {
      name: 'partnerCalculationUserList',
      path: '/partner/partnerCalculationUserList',
      component: () => import('@/views/member/partner/calculationUserList.vue'),
      meta: {
        layout: 'default',
        text: '파트너 페이지',
        gnb: false
      }
    },
    {
      name: 'partnerMemberList',
      path: '/partner/partnerMemberList',
      component: () => import('@/views/member/partner/memberList.vue'),
      meta: {
        layout: 'default',
        text: '파트너 페이지',
        gnb: false
      }
    },
    {
      name: 'partnerMoneyMoveList',
      path: '/partner/partnerMoneyMoveList',
      component: () => import('@/views/member/partner/moneyMove.vue'),
      meta: {
        layout: 'default',
        text: '파트너 페이지',
        gnb: false
      }
    }
  ]
}
