import httpClient from '@/libs/http-client'

export function commonBoardCode (params) {
  const url = '/api/common/code'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export function commonCode (params) {
  const url = '/api/common/gameCodes'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function gameGroupCodes (params) {
  const url = '/api/common/gameGroupCodes'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
