<template>
  <router-link :to="{name: link}" :class="className">{{text}}</router-link>
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    link: {
      default: '',
      type: String
    },
    text: {
      default: '',
      type: String
    },
    className: {
      default: '',
      type: String
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
